
import {computed, defineComponent} from 'vue'
import store from "@/store";
import Timelines from "@/views/timeline/Timelines.vue";

export default defineComponent({
  name: "Overview",
  components: {Timelines},
  setup() {
    const entity = computed(() => store.state.ProcessModule.entity);
    return {
      entity,
    }
  }
})

